import parsePhoneNumber from 'libphonenumber-js';

var cgiPoll;
var stopPoll = false;
var pollStarted = false;
var isQRLogin;

jQuery(document).ready(function ($) {
    const UNEXPECTED_ERROR_MESSAGE = 'Ett oväntat fel har uppstått. Vänligen försök igen eller kontakta kundtjänst om felet återkommer.';

    // Authenticate user upon log in
    $('#cgi-login-form').on('submit', function (e) {
        e.preventDefault();
        let data;

        if (isQRLogin) {
            data = {
                action: 'cgi_login_qr_user',
                security: CGIAjax.security
            };
        } else {
            data = {
                action: 'cgi_login_user',
                security: CGIAjax.security
            };
        }

        $('.cgi-login-loader').removeClass('hidden');
        $('#cgi-login-form .cgi-message').html('').hide();

        $.post(CGIAjax.ajaxurl, data, function (response) {
            try {
                response = jQuery.parseJSON(response);
            }
            catch {
                alert(UNEXPECTED_ERROR_MESSAGE);
                window.location.assign(CGIAjax.logIn);
                return;
            }

            if (response.status == 'ok') {
                stopPoll = false;
                pollStarted = true;

                if (isQRLogin) {
                    $('.cgi-login-loader .eclipse').hide();
                    $('.cgi-login-loader-header').html('Starta BankID-appen och läs av QR-koden');

                    var qrcode = new QRCode({
                        content: response.data.qrData,
                        width: 160,
                        height: 160,
                        join: true
                    });
                    var svg = qrcode.svg();
                    document.getElementById("qr-code").innerHTML = svg;
                }

                pollUser();
            } else {
                try {
                    $('#cgi-login-form .cgi-message').html(response.error[0].message ?? UNEXPECTED_ERROR_MESSAGE).show();
                } catch {
                    $('#cgi-login-form .cgi-message').html(UNEXPECTED_ERROR_MESSAGE).show();
                }
                $('.cgi-login-loader').addClass('hidden');
                $('.cgi-login-loader-header').html('Inloggning startar...');
                $('.cgi-login-loader-instructions').html('<a class="cgi-abort-login" href="#">Avbryt</a>');
                $('#qr-code').html('');
            }
        });
    });

    $('#cgi-login-form').on('click', '.qr-code-login', function (e) {
        isQRLogin = true;
        e.preventDefault();
        $('#cgi-login-form .cgi-message').html('').hide();
        /* $('#cgi_ssn').val(''); */
        $('#cgi-login-form').submit();
    });

    $('#cgi-login-form').on('click', '.this-unit-login', function (e) {
        e.preventDefault();
        $('#cgi-login-form .cgi-message').html('').hide();
        $('#cgi-login-form').submit();
    });

    $('#cgi-login-form').on('click', '.other-unit-login', function (e) {
        isQRLogin = true;
        e.preventDefault();
        $('#cgi-login-form .cgi-message').html('').hide();
        $('#cgi-login-form').submit();
    });


    $('#cgi-login-form').on('click', '.btn-abort-pnr-login', function (e) {
        e.preventDefault();
        $('#cgi-login-form .cgi-message').html('').hide();
        $('.hidden-ssn').hide();

        if ($(this).hasClass('is-mobile')) {
            $('#cgi-login-form .btn-abort-other-unit-login').show();
        }

        $('#cgi-login-form .qr-code-login').removeClass('hidden');
        $('#cgi-login-form .pnr-login').removeClass('hidden');
    });

    $('#cgi-login-form').on('click', '.btn-abort-other-unit-login', function (e) {
        e.preventDefault();
        $('#cgi-login-form .cgi-message').html('').hide();

        $('#cgi-login-form .btn-abort-other-unit-login').hide();

        $('#cgi-login-form .this-unit-login').removeClass('hidden');
        $('#cgi-login-form .other-unit-login').removeClass('hidden');

        $('#cgi-login-form .qr-code-login').addClass('hidden');
        $('#cgi-login-form .pnr-login').addClass('hidden');
    });

    // Poll user login
    function pollUser() {
        var data = {
            action: 'cgi_poll_user',
            security: CGIAjax.security,
        };

        $.ajax({
            url: CGIAjax.ajaxurl,
            data: data,
            error: function () {
                if (!stopPoll) {
                    cgiPoll = setTimeout(pollUser, 1000);
                }
            },
            success: function (response) {
                try {
                    response = jQuery.parseJSON(response);
                }
                catch {
                    alert(UNEXPECTED_ERROR_MESSAGE);
                    window.location.assign(CGIAjax.logIn);
                    return;
                }

                $('.cgi-login-loader .eclipse').show();

                if (response.status == 'complete') {
                    $('.cgi-login-loader-header').html('Inloggning godkänd.');
                    $('.cgi-login-loader-instructions').html('Skickar vidare till Mina Sidor...');
                    $('#qr-code').html('');

                    var data = {
                        action: 'cgi_get_cards',
                        security: CGIAjax.security,
                    };

                    $.post(CGIAjax.ajaxurl, data, function (response) {
                        try {
                            var json_response = jQuery.parseJSON(response);
                        }
                        catch {
                            alert(UNEXPECTED_ERROR_MESSAGE);
                            window.location.assign(CGIAjax.logIn);
                            return;
                        }

                        if (!json_response) {
                            alert(UNEXPECTED_ERROR_MESSAGE);
                            window.location.assign(CGIAjax.logIn);
                            return;
                        }

                        if (json_response.status_code == 307) {
                            window.location.assign(json_response.redirect_url);
                            return;
                        }

                        if (json_response.status == 'ok') {
                            if (CGIAjax.myPagesAfterLogin) {
                                window.location.assign(CGIAjax.myPagesAfterLogin);
                            } else {
                                if (CGIAjax.redirectUrl) {
                                    window.location.assign(CGIAjax.redirectUrl);
                                } else {
                                    window.location.assign(CGIAjax.myPagesOverview);
                                }
                            }
                        } else {
                            if (json_response.status_code == 404) {
                                window.location.assign(CGIAjax.noCommitment);
                            } else {
                                window.location.assign(CGIAjax.errorPage);
                            }
                        }
                    });

                } else if (response.status == 'error') {
                    try {
                        $('#cgi-login-form .cgi-message').html(response.error[0].message ?? UNEXPECTED_ERROR_MESSAGE).show();
                    } catch {
                        $('#cgi-login-form .cgi-message').html(UNEXPECTED_ERROR_MESSAGE).show();
                    }
                    pollStarted = false;
                    stopPoll = true;

                    $('.cgi-login-loader').addClass('hidden');
                    $('.cgi-login-loader-header').html('Inloggning startar...');
                    $('.cgi-login-loader-instructions').html('<a class="cgi-abort-login" href="#">Avbryt</a>');
                    $('#qr-code').html('');
                } else if (response.status == 'userSign') {
                    $('.cgi-login-loader-header').html('Nu kan du legitimera dig med BankID.');
                    $('.cgi-login-loader-instructions').html(response.instruction);
                    $('#qr-code').html('');
                    if (!stopPoll) {
                        cgiPoll = setTimeout(pollUser, 1000);
                    }

                } else if (response.status == 'outstandingTransaction' || response.status == 'noClient') {
                    if (isQRLogin) {
                        if (!stopPoll) {
                            $('.cgi-login-loader .eclipse').hide();
                            $('.cgi-login-loader-header').html('Starta BankID-appen och läs av QR-koden');

                            var qrcode = new QRCode({
                                content: response.qrData,
                                width: 160,
                                height: 160,
                                join: true //Crisp rendering and 4-5x reduced file size
                            });
                            var svg = qrcode.svg();
                            document.getElementById("qr-code").innerHTML = svg;

                            $('.cgi-login-loader-instructions').html('<a class="cgi-abort-login" href="#">Avbryt</a>');
                        }
                    } else {
                        $('.cgi-login-loader-header').html(response.instruction);
                        $('.cgi-login-loader-instructions').html('Klicka här för att<br /><a class="et_pb_button" href="bankid:///?' + response.autostartToken + '&redirect=null">Starta BankID-appen</a><br /><a class="cgi-abort-login" href="#">Avbryt</a>');
                        $('#qr-code').html('');

                        if (!stopPoll) {
                            stopPoll = true;
                            location.replace("bankid:///?autostarttoken=" + response.autostartToken + "&redirect=null");
                        }
                    }

                    if (!stopPoll) {
                        cgiPoll = setTimeout(pollUser, 1000);
                    }
                } else {
                    if (!stopPoll) {
                        cgiPoll = setTimeout(pollUser, 1000);
                    }
                }
            }, timeout: 20000
        });
    }

    // Logout user
    $('.cgi-logout-button').on('click', function (e) {
        e.preventDefault();

        var data = {
            action: 'cgi_logout_user',
            security: CGIAjax.security
        };

        $.post(CGIAjax.ajaxurl, data, function () {
            window.location.assign(CGIAjax.myPagesLogOut);
        });
    });

    // Abort login process
    $('.cgi-login-loader').on('click', '.cgi-abort-login', function (e) {
        e.preventDefault();

        stopPoll = true;
        pollStarted = false;
        clearInterval(cgiPoll);

        $('.cgi-login-loader-header').html('Avbryter inloggning...');
        $('.cgi-login-loader-instructions').html('');
        $('#qr-code').html('');

        var data = {
            action: 'cgi_abort_login',
            security: CGIAjax.security
        };

        $.post(CGIAjax.ajaxurl, data, function () {
            $('.cgi-login-loader').addClass('hidden');
            $('.cgi-login-loader-header').html('Inloggning startar...');
            $('.cgi-login-loader-instructions').html('<a class="cgi-abort-login" href="#">Avbryt</a>');
            $('#qr-code').html('');
        });
    });

    // Tabs
    $('.transaction-tabs').on('click', '.tab', function (e) {
        e.preventDefault();
        document.location.hash = $(this).data("id")
        $(this).closest('.transaction-tabs').find('.tab.active').removeClass('active');
        $(this).addClass('active');
        $(this).closest('.account-overview').find('.transactions-table-wrapper').hide();
        $(this).closest('.account-overview').find('.' + $(this).data('id')).show();
    });

    if (document.location.hash) {
        var hash = document.location.hash.replace('#', '');
        var tab = $('.tab[data-id=' + hash + ']')

        tab.closest('.transaction-tabs').find('.tab.active').removeClass('active');
        tab.addClass('active');
        tab.closest('.account-overview').find('.transactions-table-wrapper').hide();
        tab.closest('.account-overview').find('.' + tab.data('id')).show();
    }

    // Activate nice looking checkboxes
    var elems = Array.prototype.slice.call(document.querySelectorAll('.js-switch'));
    elems.forEach(function (html) {
        new Switchery(html, { size: 'small' });
    });

    // Save card region block status
    $('.card-regions-table').on('change', '.js-switch', function () {
        var _this = $(this);
        var cardBlocked = !_this.is(':checked');
        var regionCode = _this.val();
        var cardId = _this.data('id');

        _this.closest('tr').find('.ajax-loader').addClass('loading');

        var data = {
            action: 'cgi_save_card_region_status',
            security: CGIAjax.security,
            blocked: cardBlocked,
            region_code: regionCode,
            id: cardId
        };

        $.post(CGIAjax.ajaxurl, data, function () {
            _this.closest('tr').find('.ajax-loader').removeClass('loading');
        });
    });

    // Initiate logout modal
    $('.cgi-logout-button').avgrund({
        height: 100,
        width: 200,
        holderClass: 'logout-info',
        showClose: false,
        onBlurContainer: '.container',
        template: '<h3>Loggar ut...</h3>'
    });

    $('.choose-ipp-status').avgrund({
        height: 300,
        width: 300,
        holderClass: 'choose-ipp-status-popup',
        showClose: false,
        onBlurContainer: '.container',
        onLoad: function (elem) {
            $('.change-ipp-template button').attr('data-sequence_number', elem.data('sequence_number'));
            $('.change-ipp-template').show();
        },
        template: $('.change-ipp-template')
    });

    if ($('#has-changeable-ipp').length) {
        setTimeout(function () {
            $(document).avgrund({
                height: 250,
                width: 300,
                holderClass: 'has-changeable-ipp-status-popup',
                showClose: false,
                onBlurContainer: '#page-container',
                openOnEvent: false,
                onLoad: function () {
                    $('.has-changeable-ipp').show();
                },
                template: $('.has-changeable-ipp')
            });
        }, 500);
    }

    $(document).on('click', '.change-ipp-template .btn-change', function () {
        var id = $(this).data('card_id');
        var sequence_number = $(this).data('sequence_number');
        var purchase_code = $(this).data('purchase_code');
        var months = $(this).data('months');

        $('.change-ipp-template .loading-overlay .months').html(months);
        $('.change-ipp-template .loading-overlay').fadeIn();


        var data = {
            action: 'cgi_change_ipp_purchase_code',
            id: id,
            sequence_number: parseInt(sequence_number),
            purchase_code: purchase_code,
            security: CGIAjax.security,
        };

        $.ajax({
            url: CGIAjax.ajaxurl,
            data: data,
            error: function () {
            },
            success: function () {
                document.location.hash = 'ipp-summary';
                window.location.reload(true);
            }
        });
    });

    $(document).on('click', '.has-changeable-ipp-status-popup .ipp-dont-remind-me', function (e) {
        e.preventDefault();

        var data = {
            action: 'cgi_ipp_dont_remind_me',
            security: CGIAjax.security,
        };

        $('.has-changeable-ipp .loading-overlay').fadeIn();

        $.ajax({
            url: CGIAjax.ajaxurl,
            data: data,
            error: function () {
            },
            success: function () {
                $('.avgrund-overlay').trigger('click')
                $('.has-changeable-ipp .loading-overlay').fadeOut();
            }
        });
    });

    $('.activate-card').on('click', function (e) {
        e.preventDefault();

        var id = $(this).data('card_id');
        var ajaxLoader = $(this).parent().find('.ajax-loader');

        ajaxLoader.addClass('loading');


        var data = {
            action: 'cgi_activate_card',
            id: id,
            security: CGIAjax.security,
        };

        $.ajax({
            url: CGIAjax.ajaxurl,
            data: data,
            error: function () {
            },
            success: function (response) {
                ajaxLoader.removeClass('loading');

                try {
                    response = jQuery.parseJSON(response);
                }
                catch {
                    alert(UNEXPECTED_ERROR_MESSAGE);
                    window.location.assign(CGIAjax.logIn);
                    return;
                }

                if (response.status == 'error') {
                    if (typeof response.error[0].message !== 'undefined') {
                        $('.activate-card-wrapper .cgi-error').html('<p class="cgi-message">' + response.error[0].message + '</p>');
                    } else {
                        $('.activate-card-wrapper .cgi-error').html('<p class="cgi-message">Något gick fel. Var god försök igen senare.</p>');
                    }
                } else {
                    location.reload();
                }
            }
        });
    });

    $('#edit-contact-info').on('submit', function (e) {
        e.preventDefault();

        var ajaxLoader = $(this).find('.ajax-loader');

        $('#edit-contact-info .cgi-form-col').removeClass('error');
        $('#edit-contact-info .cgi-form-col label .error').remove();

        var form_data = $(this).serializeArray().reduce(function (obj, item) {
            obj[item.name] = item.value;
            return obj;
        }, {});

        let isValid = true;

        if (!isValidPhoneNumber(form_data.phone)) {
            $('#edit-contact-info .phone label').append('<span class="error">Mobilnummer fel format</span>');
            $('#edit-contact-info .phone').addClass('error');
            isValid = false;
        }

        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(form_data.email)) { // eslint-disable-line no-useless-escape
            $('#edit-contact-info .email label').append('<span class="error">E-mail fel format</span>');
            $('#edit-contact-info .email').addClass('error');
            isValid = false;
        }

        if (!isValid) return false;

        ajaxLoader.addClass('loading');
        var oldEtag = form_data.etag;

        var data = {
            action: 'cgi_update_contact_info',
            address_type: form_data.address_type,
            email: form_data.email,
            phone: form_data.phone,
            etag: form_data.etag,
            security: CGIAjax.security,
        };

        $.ajax({
            url: CGIAjax.ajaxurl,
            data: data,
            method: 'POST',
            error: function () {
            },
            success: function (response) {
                try {
                    response = jQuery.parseJSON(response);
                }
                catch {
                    alert(UNEXPECTED_ERROR_MESSAGE);
                    window.location.assign(CGIAjax.logIn);
                    return;
                }

                if (response.status == 'error') {
                    ajaxLoader.removeClass('loading');
                    if (typeof response.error[0].message !== 'undefined') {
                        $('#edit-contact-info .cgi-error').html('<p class="cgi-message">' + response.error[0].message + '</p>');
                    } else {
                        $('#edit-contact-info .cgi-error').html('<p class="cgi-message">Något gick fel. Var god försök igen senare.</p>');
                    }
                } else {
                    if (isSuccessStatusCode(response.status_code)) {
                        if (response.etag == oldEtag) {
                            ajaxLoader.removeClass('loading');
                            $('.confirm-profile').slideUp();
                        } else {
                            location.reload();
                        }
                    } else {
                        ajaxLoader.removeClass('loading');
                        $('#edit-contact-info .cgi-error').html('<p class="cgi-message">Något gick fel när vi sparade dina uppgifter. Prova gärna att <a href=".">ladda om sidan</a> och försök igen.</p>');
                    }
                }
            }
        });
    });

    $('#verify-contact-info').on('submit', function (e) {
        e.preventDefault();

        var ajaxLoader = $(this).find('.ajax-loader');

        var form_data = $(this).serializeArray().reduce(function (obj, item) {
            obj[item.name] = item.value;
            return obj;
        }, {});

        ajaxLoader.addClass('loading');

        var data = {
            action: 'cgi_update_contact_info',
            address_type: form_data.address_type,
            etag: form_data.etag,
            security: CGIAjax.security,
        };

        $.ajax({
            url: CGIAjax.ajaxurl,
            data: data,
            method: 'POST',
            error: function () {
            },
            success: function () {
                $('.confirm-profile').slideUp();
            }
        });
    });

    $('#verify-contact-info .edit-profile').on('click', function (e) {
        e.preventDefault();
        $('.edit-contact-info-wrapper').slideToggle();
    });

    $('.cgi-sms-notification').on('change', '.js-switch', function (e) {
        e.preventDefault();

        let _this = $(this);
        let value = _this.is(':checked');
        let etag = _this.data('etag');
        let ajaxLoader = _this.parent().find('.ajax-loader');
        ajaxLoader.addClass('loading');
        $('.cgi-sms-notification .cgi-error').html('');

        let data = {
            action: 'cgi_update_sms_notification',
            sms_notifications: value,
            etag: etag,
            security: CGIAjax.security,
        };

        $.ajax({
            url: CGIAjax.ajaxurl,
            data: data,
            method: 'POST',
            error: function (response) {
                response = JSON.parse(response);
                $('.cgi-sms-notification .cgi-error').html('<p class="cgi-message">' + response.data.errors[0].message + '</p>');
                ajaxLoader.removeClass('loading');
            },
            success: function (response) {
                ajaxLoader.removeClass('loading');
                response = JSON.parse(response);
                _this.data('etag', response.etag);
                if (value) {
                    $('.cgi-sms-notification label').html('Ja');
                } else {
                    $('.cgi-sms-notification label').html('Nej');
                }
            }
        });
    });

    $(document).on('click', '.has-changeable-ipp-status-popup .btn-choose', function () {
        var hash = 'ipp-summary';
        var tab = $('.tab[data-id=' + hash + ']')

        tab.closest('.transaction-tabs').find('.tab.active').removeClass('active');
        tab.addClass('active');
        tab.closest('.account-overview').find('.transactions-table-wrapper').hide();
        tab.closest('.account-overview').find('.' + tab.data('id')).show();
        $('.avgrund-overlay').trigger('click');
    });

    $(document).on('click', '.change-ipp-template .btn-abort', function () {
        $('.avgrund-overlay').trigger('click');
    });

    // Change Limit Form - Part 2
    $(document).on('click', '.change-limit-wrapper #change-limit-part-2-submit', function () {
        var id = $(this).data('card_id');
        var data = {
            action: 'cgi_change_limit_part_two',
            id: id,
            reference_id: $('input[name=reference_id]').val(),
            limit_amount: $('#change-limit-amounts :selected').val(),
            sign_redirect_url: CGIAjax.myPagesLimitSuccess + '/' + id + '/',
            email: $('input[name=email]').val(),
            phone: $('input[name=phone]').val(),
            security: CGIAjax.security,
        };

        $('.change-limit-part-two-content').html('<img class="eclipse" src="' + CGIAjax.pluginDir + 'assets/images/Eclipse.gif" width="120" height="120" />');

        $.ajax({
            url: CGIAjax.ajaxurl,
            data: data,
            error: function () { },
            success: function (response) {
                try {
                    response = jQuery.parseJSON(response);
                }
                catch {
                    alert(UNEXPECTED_ERROR_MESSAGE);
                    window.location.assign(CGIAjax.logIn);
                    return;
                }

                if (response.status == 'error') {
                    if (typeof response.error[0].message !== 'undefined') {
                        $('.change-limit-part-two-content').html('<p>' + response.error[0].message + '</p>');
                    } else {
                        $('.change-limit-part-two-content').html('<p>Något gick fel. Var god försök igen.</p>');
                    }
                    $('html, body').animate({ scrollTop: $('.change-limit-wrapper').offset().top });
                } else {
                    window.location.assign(response.data.user_sign_link);
                }
            }
        });
    });

    // Get new limit
    if ($('.change-limit-wrapper.get-new-limit').length > 0) {
        setTimeout(getNewLimit, 10000);
    }

    $('.limit-results').on('click', '.get-new-limit-try-again', function () {
        $('.limit-results').html('<img class="eclipse" src="' + CGIAjax.pluginDir + 'assets/images/Eclipse.gif" width="120" height="120" /><br />');
        getNewLimit();
    });

    function getNewLimit() {
        var selected_card = $('input[name=selected_card]').val();
        var old_limit = $('input[name=old_limit]').val();
        var data = {
            action: 'cgi_get_new_limit',
            selected_card: selected_card,
            security: CGIAjax.security,
        };

        $.ajax({
            url: CGIAjax.ajaxurl,
            data: data,
            error: function () { },
            success: function (response) {
                if (response == false) {
                    $('.limit-results').html('<p>Något gick fel. Var god försök igen.<br /><br /></p>');
                    $('.limit-results').append('<a class="et_pb_button cgi-submit-input get-new-limit-try-again">Försök igen</a>');
                } else {
                    if (old_limit == response) {
                        $('.limit-results').html('<p>Din nya kreditgräns kunde inte uppdateras, försök igen, ingen ny kreditupplysning tas.<br /><br /></p>');
                        $('.limit-results').append('<a class="et_pb_button cgi-submit-input get-new-limit-try-again">Försök igen</a>');
                    } else {
                        $('.limit-results').html('<p>Din nya kreditgräns är ' + formatMoney(response, 0, ',', ' ') + ' kr och finns nu tillgänglig på ditt kort.<br /><br /></p>');
                        $('.limit-results').append('<a class="et_pb_button cgi-submit-input" href="' + CGIAjax.myPagesOverview + '">Tillbaka till översikt</a>');
                    }
                }
            }
        });
    }


    $(document).on('click', '.get-card-pin', function (e) {
        e.preventDefault();
        var id = $(this).data('card_id');
        var data = {
            action: 'cgi_get_card_pin',
            id: id,
            security: CGIAjax.security,
        };
        var btn = $(this);
        var generatedPin = btn.parent().find('.generated-pin-link');
        btn.hide();
        generatedPin.show();

        $.ajax({
            url: CGIAjax.ajaxurl,
            data: data,
            error: function () { },
            success: function (response) {
                try {
                    response = jQuery.parseJSON(response);
                }
                catch {
                    alert(UNEXPECTED_ERROR_MESSAGE);
                    window.location.assign(CGIAjax.logIn);
                    return;
                }

                if (response.status == 'error') {
                    if (typeof response.error[0].message !== 'undefined') {
                        generatedPin.html(response.error[0].message);
                    } else {
                        generatedPin.html('Något gick fel. Var god försök igen.');
                    }
                } else {
                    generatedPin.html('<a href="' + response.data.card_pin_link + '" target="_blank">Visa PIN-kod (Öppnas i nytt fönster)</a>');
                }
            }
        });
    });

    if (localStorage.getItem('bonus-info-open') != null && localStorage.getItem('bonus-info-open') == 'true') {
        $('.bonus-info').slideDown();
    }

    // Open user menu on click
    $('.cgi-user-menu.is-logged-in').on('click', '.user-icon', function () {
        $('.cgi-user-menu.is-logged-in .cgi-user-info').slideToggle();
        $('.cgi-menu-overlay').fadeOut();
    })

    $('.cgi-user-menu.is-logged-in').on('click', '.cgi-menu-button', function () {
        $('.cgi-user-menu.is-logged-in .cgi-user-info').slideToggle(400, function () {
            if ($('.cgi-user-info').is(':visible')) {
                $('.cgi-menu-overlay').fadeIn();
            } else {
                $('.cgi-menu-overlay').fadeOut();
            }
        });
        $('.bonus-info').slideUp();
    })

    $('.cgi-user-menu.is-logged-in').on('click', '.cgi-bonus-button', function () {
        $('.bonus-info').slideToggle(400, function () {
            if ($('.bonus-info').is(':visible')) {
                localStorage.setItem('bonus-info-open', true);
            } else {
                localStorage.setItem('bonus-info-open', false);
            }
        });
        $('.cgi-user-menu.is-logged-in .cgi-user-info').slideUp();
        $('.cgi-menu-overlay').fadeOut();
    })

    $('.bonus-info').on('click', '#close-bonus-menu', function () {
        $('.bonus-info').slideUp();
        localStorage.setItem('bonus-info-open', false);
        $('.cgi-menu-overlay').fadeOut();
    })

    // Close user menu when clicking outside of menu
    $('.cgi-menu-overlay').on('click', function () {
        $('.cgi-user-menu.is-logged-in .cgi-user-info').slideUp();
        $('.bonus-info').slideUp();
        $('.cgi-menu-overlay').fadeOut();
    })

    // Validate and calculate voucher value when entering points
    $('.account-bonus .add-points').on('keyup', 'input[type=tel]', function () {
        var conversionFactor = parseInt($('#conversion_factor').val());
        var totalPoints = parseInt($('#total_points').val());
        var maxPoints = parseInt($('#max_burn_bonus_points').val());
        var minPoints = parseInt($('#min_burn_bonus_points').val());
        var points = parseInt($(this).val());

        if (points > totalPoints) {
            points = totalPoints;
            $('#points-to-convert').val(points);
        }

        if (points > maxPoints) {
            points = maxPoints;
            $('#points-to-convert').val(points);
        }

        if (points < minPoints) {
            points = 0;
        }

        $('.account-bonus .add-points .points-calculation span').html(numberFormat(Math.round(points / conversionFactor)));

        return;
    });


    // Open modal to confirm creation of voucher
    let confirmModal = $('[data-remodal-id=modal]').remodal();
    let errorModal = $('[data-remodal-id=error-modal]').remodal();

    $('#create-voucher-form').on('submit', function (e) {
        e.preventDefault();
        validateVoucher();
    });

    function validateVoucher() {
        var points = parseInt($('#points-to-convert').val());
        var totalPoints = parseInt($('#total_points').val());
        var minPoints = parseInt($('#min_burn_bonus_points').val());
        var maxPoints = parseInt($('#max_burn_bonus_points').val());
        var distributionType = $("input[name=distribution_type]:checked").val();
        var conversionFactor = parseInt($('#conversion_factor').val());
        var errors = false;
        var errorMessage = '';

        if (points == 0 || isNaN(points)) {
            errorMessage = 'Du måste omvandla minst ' + minPoints + ' poäng.';
            errors = true;
            points = 0;
        }
        if (points < minPoints) {
            errorMessage = 'Du måste konvertera minst ' + minPoints + ' poäng.';
            errors = true;
        }
        if (points > maxPoints) { points = maxPoints; }

        $('.remodal-points').html(points);
        $('.remodal-sum').html(Math.round(points / conversionFactor));
        $('.points-calculation span').html(Math.round(points / conversionFactor));
        $('#points-to-convert').val(points);

        if (distributionType == 'email') {
            var email = $('input[name=distribution_email]').val();
            if (email.length < 1) {
                errorMessage = 'Vänligen kontrollera att du fyllt i korrekt e-mail.';
                errors = true;
            }
            $('.remodal-distribution-type').html('E-mail: ');
            $('.remodal-receiver').html(email);
        } else if (distributionType == 'sms') {
            var phoneNumber = $('input[name=distribution_sms]').val();
            $('.remodal-distribution-type').html('SMS: ');
            $('.remodal-receiver').html(phoneNumber);

            if (phoneNumber.length < 10) {
                errorMessage = 'Vänligen kontrollera att du fyllt i korrekt telefonnummer.';
                errors = true;
            } else if (phoneNumber.substring(0, 2) == '07') {
                phoneNumber = '0046' + phoneNumber.substring(1);
            } else if (phoneNumber.substring(0, 1) == '+') {
                phoneNumber = '00' + phoneNumber.substring(1);
            }
            $('input[name=distribution_sms]').val(phoneNumber);
        } else {
            errorMessage = 'Du måste välja leveranssätt.';
            errors = true;
        }

        if (!errors) {
            confirmModal.open();
        } else {
            $('.modal-error-message').html(errorMessage);
            errorModal.open();
        }

        return false;
    }

    // Create Voucher
    $('.create-voucher.remodal-confirm').on('click', function (e) {
        e.preventDefault();
        createVoucher()
    });

    function createVoucher() {
        $('.account-bonus .bonus-overlay').removeClass('hidden');

        var distributionType = $("input[name=distribution_type]:checked").val();
        var receiverEmail = $('input[name=distribution_email]').val();
        var receiverSmsPhone = $('input[name=distribution_sms]').val();

        var data = {
            action: 'cgi_create_voucher',
            security: CGIAjax.security,
            id: parseInt($('input[name=id]').val()),
            distributionType: distributionType,
            receiverEmail: receiverEmail,
            receiverSmsPhone: receiverSmsPhone,
            points: parseInt($('#points-to-convert').val())
        };

        $.post(CGIAjax.ajaxurl, data, function (response) {
            try {
                response = jQuery.parseJSON(response);
            }
            catch {
                alert(UNEXPECTED_ERROR_MESSAGE);
                location.reload();
                return;
            }

            if (response.status == 'error') {
                if (typeof response.errors !== 'undefined') {
                    $('.bonus-overlay-content').html('<h3>Något gick fel</h3><p>' + response.errors + '</p><p><a href="#" class="create-voucher-try-again">Försök igen</a></p>');
                } else if (typeof response.error.message !== 'undefined') {
                    $('.bonus-overlay-content').html('<h3>Något gick fel</h3><p>' + response.error.message + '</p><p><a href="#" class="create-voucher-try-again">Försök igen</a></p>');
                } else {
                    $('.bonus-overlay-content').html('<h3>Något gick fel</h3><p>Var god försök igen.</p><p><a href="">Försök igen</a></p>');
                }
            } else {
                if (response.data.status == 'OK') {

                    let data = {
                        action: 'cgi_update_products',
                        security: CGIAjax.security,
                    };

                    $.post(CGIAjax.ajaxurl, data, function (response) {
                        try {
                            response = jQuery.parseJSON(response);
                        }
                        catch {
                            alert(UNEXPECTED_ERROR_MESSAGE);
                            location.reload();
                            return;
                        }

                        let minPoints = parseInt($('#min_burn_bonus_points').val());
                        let maxPoints = parseInt($('#max_burn_bonus_points').val());
                        let conversionFactor = parseInt($('#conversion_factor').val());

                        $('input[name=distribution_sms]').val('');
                        $('input[name=distribution_email]').val('');
                        $('input[name=distribution_type]').prop('checked', false);

                        if (typeof response.data.cards !== 'undefined') {
                            let cards = response.data.cards;

                            for (let i = 0; i < cards.length; i++) {
                                if (typeof cards[i].card_type !== 'undefined' && cards[i].card_type == 0) {
                                    if (typeof cards[i].loyalty_balances.balances[0].balance !== 'undefined') {
                                        let balance = parseInt(cards[i].loyalty_balances.balances[0].balance);

                                        if (balance > maxPoints) {
                                            $('#points-to-convert').val(maxPoints);
                                            $('.points-calculation span').html(numberFormat(Math.round(maxPoints / conversionFactor)));
                                        } else {
                                            $('#points-to-convert').val(balance);
                                            $('.points-calculation span').html(numberFormat(Math.round(balance / conversionFactor)));
                                        }

                                        $('.bonus-total span').html(numberFormat(balance) + ' poäng');
                                        $('#total_points').val(balance);

                                        if (balance < minPoints) {
                                            $('#create-voucher-form').html('<h3 class="cgi-filled-primary">Lös in poäng</h3><div class="bonus-content bordered-primary not-enough-points"><p>Minimumpoäng för att skapa presentkort: ' + numberFormat(minPoints) + ' poäng</p></div>');
                                        }
                                    }
                                }
                            }
                        }

                        let sentTo;
                        if (distributionType == 'email') {
                            sentTo = receiverEmail;
                        } else {
                            sentTo = receiverSmsPhone;
                        }
                        $('.bonus-overlay-content').html('<h3>Presentkort skickat!</h3><p>Presentkortet är nu skickat till ' + sentTo + '.</p><br /><p><a href="#" class="et_pb_button hide-overlay">OK</a></p>');
                    });

                } else {
                    $('.bonus-overlay-content').html('<h3>Något gick fel</h3><p>' + response.error + '</p>');
                }
            }
        });
    }

    $('.bonus-overlay').on('click', '.create-voucher-try-again', function (e) {
        e.preventDefault();

        $('.bonus-overlay').addClass('hidden');
        $('.bonus-overlay-content').html('<h3>Skapar presentkort</h3><img class="eclipse" src="' + CGIAjax.pluginDir + 'assets/images/Eclipse.gif" width="120" height="120" /><p>Var god vänta...</p>');
    });

    $('.bonus-overlay').on('click', '.hide-overlay', function (e) {
        e.preventDefault();

        $('.bonus-overlay').addClass('hidden');
        $('.bonus-overlay-content').html('<h3>Skapar presentkort</h3><img class="eclipse" src="' + CGIAjax.pluginDir + 'assets/images/Eclipse.gif" width="120" height="120" /><p>Var god vänta...</p>');
    });


    $('input[name=distribution_sms]').on('change', function () {
        let $radios = $('input:radio[name=distribution_type]');
        if ($radios.is(':checked') === false) {
            $radios.filter('[value=sms]').prop('checked', true);
        }
    });

    $('input[name=distribution_email]').on('change', function () {
        let $radios = $('input:radio[name=distribution_type]');
        if ($radios.is(':checked') === false) {
            $radios.filter('[value=email]').prop('checked', true);
        }
    });

    $('input.only-numbers').on('keydown', function (e) {
        if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 190]) !== -1 ||
            // Allow: Ctrl+A
            (e.keyCode == 65 && (e.ctrlKey === true || e.metaKey === true)) ||
            // Allow: Ctrl+C
            (e.keyCode == 67 && (e.ctrlKey === true || e.metaKey === true)) ||
            // Allow: Ctrl+X
            (e.keyCode == 88 && (e.ctrlKey === true || e.metaKey === true)) ||
            // Allow: home, end, left, right
            (e.keyCode >= 35 && e.keyCode <= 39) ||
            // Allow numbers
            (!e.shiftKey && (((e.keyCode >= 48 && e.keyCode <= 57)) || (e.keyCode >= 96 && e.keyCode <= 105)))) {
            // let it happen, don't do anything
            return;
        }
        // Ensure that it is a number and stop the keypress
        if ((!e.shiftKey && (e.keyCode < 48 || e.keyCode > 57)) || (e.keyCode < 96 || e.keyCode > 105)) {
            e.preventDefault();
        }
    });


    function numberFormat(x) {
        let parts = x.toString().split(",");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        return parts.join(",");
    }

    (function () {
        let hidden = "hidden";

        // Standards:
        if (hidden in document)
            document.addEventListener("visibilitychange", onchange);
        else if ((hidden = "mozHidden") in document)
            document.addEventListener("mozvisibilitychange", onchange);
        else if ((hidden = "webkitHidden") in document)
            document.addEventListener("webkitvisibilitychange", onchange);
        else if ((hidden = "msHidden") in document)
            document.addEventListener("msvisibilitychange", onchange);
        // IE 9 and lower:
        else if ("onfocusin" in document)
            document.onfocusin = document.onfocusout = onchange;
        // All others:
        else
            window.onpageshow = window.onpagehide
                = window.onfocus = window.onblur = onchange;

        function onchange(evt) {
            let v = "visible", h = "hidden",
                evtMap = {
                    focus: v, focusin: v, pageshow: v, blur: h, focusout: h, pagehide: h
                };
            let status;
            evt = evt || window.event;
            if (evt.type in evtMap)
                status = evtMap[evt.type]
            else
                status = this[hidden] ? "hidden" : "visible";

            if (status == 'hidden') {
                stopPoll = true;
            } else {
                if (stopPoll && pollStarted) {
                    stopPoll = false;
                    cgiPoll = setTimeout(pollUser, 1000);
                }
            }
        }

        // set the initial state (but only if browser supports the Page Visibility API)
        if (document[hidden] !== undefined)
            onchange({ type: document[hidden] ? "blur" : "focus" });
    })();

    if (window.location.pathname == '/mina-sidor/') {
        if ($('#is_profile_confirmed').length && !$('#is_profile_confirmed').val()) {
            setTimeout(openMemberPopup, 500);
        }

        //setTimeout(openMemberPopup, 500);
    }

    function openMemberPopup() {
        $(document).avgrund({
            height: 240,
            width: 300,
            holderClass: 'contact-info',
            showClose: false,
            closeByEscape: false,
            closeByDocument: false,
            onBlurContainer: '#page-container',
            openOnEvent: false,
            template: '<a class="contact-info-link" href="/min-profil/"><div id="accept-contact-info-blurb-avgrund" class="et_pb_module et_pb_blurb et_clickable et_pb_bg_layout_light et_pb_text_align_left"><div class="et_pb_blurb_content"><div class="et_pb_main_blurb_image"><span class="et_pb_image_wrap"><span class="et-pb-icon et-waypoint et_pb_animation_top et-animated">r</span></span></div><div class="et_pb_blurb_container"><h4 class="et_pb_module_header">Välkommen</h4><div class="et_pb_blurb_description"><p>Första gången du loggar in vill vi att du uppdaterar eller bekräftar de kontaktuppgifter vi har till dig.</p><p>Klicka här för att komma till<br /><strong>Min profil</strong></p></div></div></div></div></a>'
        });
    }

    $('.transactions-summary-wrapper').on('click', '.year-toggle', function (e) {
        e.preventDefault();

        $(this).toggleClass('open');
        $('.invoice-row-' + $(this).data('year')).slideToggle();
    });

});

function isSuccessStatusCode(statusCode) {
    return (statusCode >= 200 && statusCode <= 300);
}

function isValidPhoneNumber(phone) {
    try {
        const validPhone = parsePhoneNumber(phone, 'SE');
        return validPhone.isValid() && validPhone.country === 'SE';
    } catch {
        return false;
    }
}

function showPepQuestions(pepType) {
    if (pepType == "myself") {
        $('.pep-question[data-step="3"]').removeClass('hidden');
        $('.pep-question[data-step="5"]').addClass('hidden');
        $('.pep-question[data-step="6"]').addClass('hidden');
        $('.pep-question[data-step="7"]').addClass('hidden');
        $('.pep-question[data-step="8"]').addClass('hidden');
    } else if (pepType == "related") {
        $('.pep-question[data-step="3"]').addClass('hidden');
        $('.pep-question[data-step="5"]').removeClass('hidden');
        $('.pep-question[data-step="6"]').removeClass('hidden');
        $('.pep-question[data-step="7"]').removeClass('hidden');
        $('.pep-question[data-step="8"]').addClass('hidden');
    } else {
        $('.pep-question[data-step="3"]').addClass('hidden');
        $('.pep-question[data-step="5"]').removeClass('hidden');
        $('.pep-question[data-step="6"]').addClass('hidden');
        $('.pep-question[data-step="7"]').removeClass('hidden');
        $('.pep-question[data-step="8"]').removeClass('hidden');
    }
}

jQuery(document).ready(function ($) {

    $('input[name="politically_exposed"]').on('click', function () {
        if (this.value == "true") {
            $('.pep-questions').removeClass('hidden');
        } else {
            $('.pep-questions').addClass('hidden');
        }
    });

    $('input[name="type"]').on('click', function () {
        showPepQuestions($(this).data('pep-type'));
    });

    if ($('input[name="politically_exposed"]:checked').val() == 'true') {
        $('.pep-questions').removeClass('hidden');
    } else {
        $('.pep-questions').addClass('hidden');
    }

    if ($('input[name="type"]:checked').data('pep-type') != undefined) {
        showPepQuestions($('input[name="type"]:checked').data('pep-type'));
    }

    $.validator.setDefaults({
        ignore: [],
        errorElement: 'span',
        errorClass: 'invalid',
        errorPlacement: function (error, element) {
            var placement = $(element).data('error');
            if (placement) {
                $(placement).append(error)
            } else {
                error.insertAfter(element);
            }
        },
        validClass: "valid",
    });

    $('#change-limit-form-part-1').validate({
        rules: {
            monthly_income: {
                required: true,
                number: true,
            },
            employment_type: "required",
            housing: "required",
            adults: "required",
            children: "required",
            villkor: "required",
            politically_exposed: "required",
            type: {
                required: {
                    depends: function () {
                        return $("#politically_exposed_yes").is(":checked");
                    }
                }
            },
            function: {
                required: {
                    depends: function () {
                        return ($("#politically_exposed_yes").is(":checked") && ($('input[name="type"]:checked').data('pep-type') == "myself"));
                    }
                }
            },
            title: {
                required: {
                    depends: function () {
                        return ($("#politically_exposed_yes").is(":checked") && ($('input[name="type"]:checked').data('pep-type') == "related" || $('input[name="type"]:checked').data('pep-type') == "coworker"));
                    }
                }
            },
            relation: {
                required: {
                    depends: function () {
                        return ($("#politically_exposed_yes").is(":checked") && ($('input[name="type"]:checked').data('pep-type') == "related"));
                    }
                }
            },
            coworker_type: {
                required: {
                    depends: function () {
                        return ($("#politically_exposed_yes").is(":checked") && ($('input[name="type"]:checked').data('pep-type') == "coworker"));
                    }
                }
            },
            other_name: {
                required: {
                    depends: function () {
                        return ($("#politically_exposed_yes").is(":checked") && ($('input[name="type"]:checked').data('pep-type') == "related" || $('input[name="type"]:checked').data('pep-type') == "coworker"));
                    }
                }
            },
            other_birthday: {
                number: true,
                required: {
                    depends: function () {
                        return ($("#politically_exposed_yes").is(":checked") && ($('input[name="type"]:checked').data('pep-type') == "related" || $('input[name="type"]:checked').data('pep-type') == "coworker"));
                    }
                }
            },
        },
        messages: {
            pnr: {
                required: "Ange ditt personnummer 12 siffror",
                isValidSwedishSSN: "Kontrollera personnumret",
                number: "Ange endast siffror"
            },
            amount: "Ange önskad kreditgräns",
            employment_type: "Ange din anställningsform",
            adults: "Ange antal vuxna i hushållet",
            children: "Ange antal barn under 18 år i hushållet",
            housing: "Ange typ av boende",
            monthly_income: {
                required: "Ange månadsinkomst före skatt",
                number: "Ange endast siffror"
            },
            villkor: "Vänligen acceptera villkoren.",
            politically_exposed: "Vänligen ange om du är PEP eller ej",
            type: "Vänligen ange PEP typ",
            function: "Vänligen ange funktion",
            title: "Vänligen ange titel",
            relation: "Vänligen ange relation",
            coworker_type: "Vänligen ange relation",
            other_name: "Vänligen ange namn",
            other_birthday: {
                number: "Vänligen ange endast siffror",
                required: "Vänligen ange födelsedatum",
            },
        },
        submitHandler: function (form) {
            sendLimitPartOne(form);
        }
    });

    $.validator.addMethod('isValidSwedishSSN', function (value, element) {

        if (this.optional(element)) {
            return true;
        }

        let ssn = value
            .replace(/\D/g, "")     // strip out all but digits
            .split("")              // convert string to array
            .reverse()              // reverse order for Luhn
            .slice(0, 12);          // keep only 12 digits

        // verify we got 12 digits, otherwise it is invalid
        if (ssn.length != 12) {
            return false;
        }

        var sum = ssn
            // convert to number
            .map(function (n) {
                return Number(n);
            })
            .slice(0, 10)
            // perform arithmetic and return sum
            .reduce(function (previous, current, index) {
                // multiply every other number with two
                if (index % 2) current *= 2;
                // if larger than 10 get sum of individual digits (also n-9)
                if (current > 9) current -= 9;
                // sum it up
                return previous + current;
            });

        // sum must be divisible by 10
        return 0 === sum % 10;

    });

});

// Change Limit Form
function sendLimitPartOne(form) {
    $('.change-limit-part-one-content').html('<img class="eclipse" src="' + CGIAjax.pluginDir + 'assets/images/Eclipse.gif" width="120" height="120" />');

    var form_data = $(form).serializeArray().reduce(function (obj, item) {
        obj[item.name] = item.value;
        return obj;
    }, {});

    var id = form_data.id;
    var request = {
        monthly_income: form_data.monthly_income,
        adults: form_data.adults,
        children: form_data.children,
        housing: form_data.housing,
        employment_type: form_data.employment_type,
        politically_exposed: form_data.politically_exposed,
    };

    if (form_data.politically_exposed == 'true') {
        request.exposure = {
            type: form_data.type,
            function: form_data.function,
            role: form_data.role,
            title: form_data.title,
            relation: form_data.relation,
            other_name: form_data.other_name,
            other_birthday: form_data.other_birthday,
            coworker_type: form_data.coworker_type
        }
    }

    var data = {
        action: 'cgi_change_limit_part_one',
        id: id,
        request: request,
        security: CGIAjax.security,
    };

    $.ajax({
        url: CGIAjax.ajaxurl,
        data: data,
        error: function () {
        },
        success: function (response) {
            try {
                response = jQuery.parseJSON(response);
            }
            catch {
                alert('Ett oväntat fel har uppstått. Vänligen försök igen eller kontakta kundtjänst om felet återkommer.');
                window.location.assign(CGIAjax.logIn);
                return;
            }

            if (response.status == 'error') {
                if (typeof response.error[0].message !== 'undefined') {
                    $('.change-limit-part-one-content').html('<p>' + response.error[0].message + '</p>');
                } else {
                    $('.change-limit-part-one-content').html('<p>Något gick fel. Var god försök igen senare.</p>');
                }
                $('html, body').animate({ scrollTop: $('.change-limit-wrapper').offset().top });
            } else {
                if (typeof response.data !== 'undefined' && response.data != null) {
                    $('.change-limit-part-one-content').hide();
                    $('.change-limit-part-two-content').show();
                    $('input[name=reference_id]').val(response.data.reference_id);
                    var amounts = response.data.amounts;
                    amounts.forEach(addAmount);
                } else {
                    $('.change-limit-part-one-content').html('<p>Något gick fel. Var god försök igen senare.</p>');
                }
            }
        }
    });
}

function addAmount(item) {
    let select = document.getElementById('change-limit-amounts');
    var opt = document.createElement('option');
    opt.value = item;
    opt.innerHTML = formatMoney(item, 0, ',', ' ') + ' kr';
    select.appendChild(opt);
}

function formatMoney(number, decPlaces, decSep, thouSep) {
    decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces,
        decSep = typeof decSep === "undefined" ? "." : decSep;
    thouSep = typeof thouSep === "undefined" ? "," : thouSep;
    var sign = number < 0 ? "-" : "";
    var i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));
    var j = (j = i.length) > 3 ? j % 3 : 0;

    return sign +
        (j ? i.substr(0, j) + thouSep : "") +
        i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
        (decPlaces ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : "");
}
